<template>
  <div>
    <h1 class="content-page_main_header margin-11">{{ pageContent.title }}</h1>
    <div class="components__container">
      <div class="components__container_split-page">
        <div class="content-page content-page_first">
          <div class="content-page_section">
            <p
              class="my-5 content-page_section_content"
              v-html="pageContent.description.replace(/\n/g, '<br>')"
            ></p>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.activities.title }}
            </h3>
            <ul class="content-page_section_content">
              <li
                v-for="(item, index) in pageContent.activities.items"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.projects.title }}
            </h3>
            <ul class="content-page_section_content">
              <li
                v-for="(item, index) in pageContent.projects.items"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
        </div>
        <!-- <div
            style="width: 400px; height: 700px; background: rgb(184, 227, 184)"
          ></div> -->
        <div class="content-page content-page_second">
          <v-card class="contant-page_contact-form">
            <v-card-title class="contant-page_contact-form_header">{{
              $t("ContactUs")
            }}</v-card-title>
            <ContactUsForm :shortForm="true" />
          </v-card>
          <ServicesList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageInfo from "../../../PageContent/WasteConsulting.json";
import ContactUsForm from "@/components/ContactUsForm.vue";
import ServicesList from "@/components/ServicesList.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "WasteConsulting",
  setup() {
    useHead({
      title: "ייעוץ אשפה ופסולת - RVc רוני ולטמן יועץ תפעול ותחזוקה",
      meta: [
        {
          // Meta Description
          name: "description",
          content:
            "הגדרת שטחים לצבירת אשפה בקומות הבניין ובנקודות איסוף מרכזיות, הגדרת דרכי העברת אשפה בנקות הצבירה, התייחסות לדרישות הרשיות למיון והפרדת אשפה והגדרת אופן פינוי האשפה לסוגיה מהאתר החוצה.",
        },
        {
          name: "keywords",
          content:
            "ייעוץ אשפה, פסולת, רוני ולטמן, יועץ תפעול, תחזוקה, מהנדס מומחה, פינוי אשפה",
        },
        {
          // Open Graph Title
          property: "og:title",
          content: "ייעוץ אשפה ופסולת - RVc רוני ולטמן יועץ תפעול ותחזוקה",
        },
        {
          // Open Graph Description
          property: "og:description",
          content:
            "הגדרת שטחים לצבירת אשפה בקומות הבניין ובנקודות איסוף מרכזיות, הגדרת דרכי העברת אשפה בנקות הצבירה, התייחסות לדרישות הרשיות למיון והפרדת אשפה והגדרת אופן פינוי האשפה לסוגיה מהאתר החוצה.",
        },
        {
          // Open Graph Type
          property: "og:type",
          content: "website",
        },
        {
          // Open Graph URL
          property: "og:url",
          content: "https://rvc.co.il/wasteConsulting",
        },
        {
          // Viewport for Responsive Design
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          // Instructions for Search Engine Bots
          name: "robots",
          content: "index, follow",
        },
      ],
    });
  },
  components: {
    ContactUsForm,
    ServicesList,
  },
  data() {
    return {
      // Your component's data goes here
    };
  },
  computed: {
    pageContent() {
      return pageInfo[this.$i18n.locale];
    },
  },
};
</script>

<style scoped>
/* Your component's scoped styles go here */
</style>
