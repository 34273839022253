<template>
  <div class="components__container">
    <h1 class="d-flex justify-center pt-5">
      {{ $t("ContactUs") }}
    </h1>
    <div class="services__grid mb-16">
      <v-card class="card-items_center">
        <div>
          <div class="center_item">
            <v-icon>
              <img :src="workingHoursIcon" alt="Working Hours Icon" />
            </v-icon>
          </div>
          <h3 class="center_item">{{ $t("WorkingHours") }}</h3>
          <div class="content-page_section_content center_item flex-column">
            <div class="card-content-direction">
              <h4 class="mx-3">
                {{ pageContent.WorkingHours[this.$i18n.locale].Weekdays }}
              </h4>
              <div>
                {{ pageContent.WorkingHours[this.$i18n.locale].WeekDaysHours }}
              </div>
            </div>
            <div class="card-content-direction my-1">
              <h4 class="mx-3">
                {{ pageContent.WorkingHours[this.$i18n.locale].Friday }}
              </h4>
              <div>
                {{ pageContent.WorkingHours[this.$i18n.locale].FridayHours }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
      <v-card class="card-items_center">
        <div>
          <div class="center_item">
            <v-icon>
              <img :src="officeLocationIcon" alt="Office Locatoin Icon" />
            </v-icon>
          </div>
          <h3 class="center_item">{{ $t("OurAddress") }}</h3>
          <div class="content-page_section_content center_item">
            {{ pageContent.Address[this.$i18n.locale].address }}
          </div>
        </div>
      </v-card>
      <v-card class="card-items_center">
        <div>
          <div class="center_item">
            <v-icon>
              <img :src="contactInfoIcon" alt="Contanct Info Icon" />
            </v-icon>
          </div>
          <h3 class="center_item">{{ $t("ContactInformation") }}</h3>
          <div class="content-page_section_content center_item flex-column">
            <div class="card-content-direction my-1">
              <h4 class="mx-3">{{ $t("Phone") }}:</h4>
              <div>
                {{ pageContent.ContactDetails[this.$i18n.locale].phone }}
              </div>
            </div>
            <div class="card-content-direction my-1">
              <h4 class="mx-3">{{ $t("Email") }}:</h4>
              <div>
                {{ pageContent.ContactDetails[this.$i18n.locale].email }}
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
    <h3 style="width: 100%" class="d-flex justify-center">
      {{ $t("WriteToUS") }}
    </h3>
    <ContactUsForm class="form__container my-8 py-5" />
  </div>
</template>

<script>
import ContactUsForm from "@/components/ContactUsForm.vue";
import pageInfo from "../../PageContent/Contact.json";
export default {
  components: {
    ContactUsForm,
  },
  data() {
    return {
      workingHoursIcon: require("@/assets/icons/working-hours.svg"),
      officeLocationIcon: require("@/assets/icons/delivery-at-work.svg"),
      contactInfoIcon: require("@/assets/icons/info.svg"),
    };
  },
  computed: {
    pageContent() {
      return pageInfo;
    },
  },
};
</script>

<style scoped>
.center_item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
  margin: 1rem;
}

.card-items_center {
  padding: 3rem;
  display: flex;
  flex-direction: column;
}

.services__grid {
  gap: 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  height: 80rem;
  max-width: 50rem;
  margin: 4rem auto;
}
.form__container {
  width: 100%;
}

.v-icon {
  font-size: 7rem;
  margin-bottom: 0.5rem;
}

.card-content-direction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (min-width: 1100px) {
  .services__grid {
    grid-template-columns: repeat(3, 1fr);
    height: 35rem;
    max-width: none;
  }
  .card-content-direction {
    flex-direction: column;
  }
}
@media screen and (min-width: 1300px) {
  .form__container {
    width: 80%;
  }
  .card-content-direction {
    flex-direction: row;
  }
  .services__grid {
    height: 30rem;
  }
}
@media screen and (min-width: 1500px) {
  .form__container {
    width: 70%;
  }
}
</style>
