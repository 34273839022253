<template>
  <div class="project">
    <div class="project__image-container">
      <img :src="getUrl()" alt="Project Image" class="project__image" />
    </div>
    <v-card
      :class="[
        { 'project__title-ltr': $i18n.locale === 'en' },
        'project__title',
      ]"
      rounded="lg"
      >{{ $t(image.text) }}</v-card
    >
  </div>
</template>

<script>
export default {
  name: "ProjectImgae",
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getUrl() {
      return require("@/" + this.image.url + "");
    },
  },
  created() {
    this.url = this.image.url;
  },
};
</script>

<style scoped>
.project {
  position: relative;
  display: inline-block;
  margin: 1rem;
  margin-bottom: 2.5rem;
}

.project__image-container {
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.project__image {
  position: relative;
  height: 100%;
  width: 100%;
  transition: transform 0.6s ease-in-out;
}
.project__title {
  align-items: center;
  bottom: -2rem;
  display: flex;
  font-size: 1.75rem;
  font-weight: 550;
  height: 4.5rem;
  justify-content: center;
  padding: 2.4rem;
  position: absolute;
  transform: translate(-12.5%, 0);
  width: 80%;
}
.project__title-ltr {
  transform: translate(12.5%, 0);
}

.project:hover .project__image {
  transform: scale(1.07);
}
.project:hover .project__title {
  /* color: #414895d8; */
  color: #414895d8;
}
</style>
