<template>
  <div>
    <div class="grid px-5">
      <ProjectImgae
        v-for="image in projectImages"
        :key="image.url"
        :image="image"
      ></ProjectImgae>
    </div>
  </div>
</template>

<script>
import ProjectImgae from "@/components/ProjectImgae.vue";

export default {
  components: {
    ProjectImgae,
  },
  data() {
    return {
      projectImages: [
        {
          text: "Projects.NationalLibraryJerusalem",
          url: "assets/images/Projects/NationalLibraryJerusalem.jpg",
        },
        {
          text: "Projects.GulfCenter",
          url: "assets/images/Projects/TheGulfCenter.jpg",
        },
        {
          text: "Projects.GenreJerusalem",
          url: "assets/images/Projects/GenreJerusalem.jpg",
        },
        {
          text: "Projects.AirForceTowers",
          url: "assets/images/Projects/AirForceTowers.jpg",
        },
        {
          text: "Projects.Amdocs",
          url: "assets/images/Projects/Amdocs.jpg",
        },
        {
          text: "Projects.GovernmentKiryatTelAviv",
          url: "assets/images/Projects/GovernmentKiryatTelAviv.jpg",
        },
        {
          text: "Projects.HaifaGovernmentOffices",
          url: "assets/images/Projects/HaifaGovernmentOffices.jpg",
        },
        {
          text: "Projects.HerzliyaMunicipalityBuilding",
          url: "assets/images/Projects/HerzliyaMunicipalityBuilding.jpg",
        },
        {
          text: "Projects.IsraelAntiquitiesAuthority",
          url: "assets/images/Projects/IsraelAntiquitiesAuthority.jpeg",
        },
        {
          text: "Projects.JerusalemCityCouncil",
          url: "assets/images/Projects/JerusalemCityCouncil.jpg",
        },
        {
          text: "Projects.RamlaBloodBank",
          url: "assets/images/Projects/RamlaBloodBank.jpg",
        },
        {
          text: "Projects.ShefayimParkingLot",
          url: "assets/images/Projects/ShefayimParkingLot.jpg",
        },
        {
          text: "Projects.TelAvivCourt",
          url: "assets/images/Projects/TelAvivCourt.jpg",
        },
        {
          text: "Projects.TelAvivMunicipalityNew",
          url: "assets/images/Projects/TelAvivMunicipalityNew.jpg",
        },
        {
          text: "Projects.TrainStationJerusalem",
          url: "assets/images/Projects/TrainStationJerusalem.jpg",
        },
      ],
    };
  },
};
</script>

<style>
.grid {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
}

.grid > * {
  aspect-ratio: 4/2;
}

@media screen and (min-width: 730px) {
  .grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 1280px) {
  .grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
