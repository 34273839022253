<template>
  <div>
    <h1 class="content-page_main_header margin-11">{{ pageContent.title }}</h1>
    <div class="components__container">
      <div class="components__container_split-page">
        <div class="content-page content-page_first">
          <div class="content-page_section">
            <p
              class="my-5 content-page_section_content"
              v-html="pageContent.description.replace(/\n/g, '<br>')"
            ></p>
          </div>
          <div class="content-page_section">
            <p
              v-for="(value, key) in pageContent.introduction"
              :key="key"
              class="my-5 content-page_section_content"
              v-html="value.replace(/\n/g, '<br>')"
            ></p>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.planing.title }}
            </h3>
            <p
              class="my-5 content-page_section_content"
              v-html="pageContent.planing.text.replace(/\n/g, '<br>')"
            ></p>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.process.title }}
            </h3>
            <p
              class="my-5 content-page_section_content"
              v-html="pageContent.process.text.replace(/\n/g, '<br>')"
            ></p>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.service.title }}
            </h3>
            <p
              v-for="(value, key) in pageContent.service.items"
              :key="key"
              class="my-5 content-page_section_content"
              v-html="value.replace(/\n/g, '<br>')"
            ></p>
          </div>
        </div>
        <!-- <div
          style="width: 400px; height: 700px; background: rgb(184, 227, 184)"
        ></div> -->
        <div class="content-page content-page_second">
          <v-card class="contant-page_contact-form">
            <v-card-title class="contant-page_contact-form_header">{{
              $t("ContactUs")
            }}</v-card-title>
            <ContactUsForm :shortForm="true" />
          </v-card>
          <ServicesList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageInfo from "../../../PageContent/OperationalDesign.json";
import ContactUsForm from "@/components/ContactUsForm.vue";
import ServicesList from "@/components/ServicesList.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "OperationalDesign",
  setup() {
    useHead({
      title:
        "בינוי תומך תפעול | גיבוש פרוגרמה תפעולית ארגונית - RVc רוני ולטמן",
      meta: [
        {
          // Meta Description
          name: "description",
          content:
            "בינוי תומך תפעול כולל לימוד תוכניות המתחם, הגדרת השטחים הנדרשים לתפעול, בדיקת נגישות לתחזוקת המערכות, שילוב דרישות לתחזוקה במפרטי ההקמה לרבות חוזי שירות לטווח ארוך במערכות מיוחדות, דרישות לספרי מתקן והגדרת כמויות חלפים רזרביים.",
        },
        {
          name: "keywords",
          content:
            "בינוי תומך תפעול, תומך תפעול, תפעול, תכנון תפעול, תכנון תומך תפעול, תכנון תפעול ארגוני, רוני ולטמן, מהנדס בכיר, אחזקה,  תכנון תפעול תומך",
        },
        {
          // Open Graph Title
          property: "og:title",
          content:
            "בינוי תומך תפעול | גיבוש פרוגרמה תפעולית ארגונית - RVc רוני ולטמן",
        },
        {
          // Open Graph Description
          property: "og:description",
          content:
            "בינוי תומך תפעול כולל לימוד תוכניות המתחם, הגדרת השטחים הנדרשים לתפעול, בדיקת נגישות לתחזוקת המערכות, שילוב דרישות לתחזוקה במפרטי ההקמה לרבות חוזי שירות לטווח ארוך במערכות מיוחדות, דרישות לספרי מתקן והגדרת כמויות חלפים רזרביים.",
        },
        {
          // Open Graph Type
          property: "og:type",
          content: "website",
        },
        {
          // Open Graph URL
          property: "og:url",
          content: "https://rvc.co.il/operationalDesign",
        },
        {
          // Viewport for Responsive Design
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          // Instructions for Search Engine Bots
          name: "robots",
          content: "index, follow",
        },
      ],
    });
  },
  components: {
    ContactUsForm,
    ServicesList,
  },
  data() {
    return {};
  },
  computed: {
    pageContent() {
      return pageInfo[this.$i18n.locale];
    },
  },
};
</script>

<style scoped></style>
