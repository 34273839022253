<template>
  <div>
    <h1 class="content-page_main_header margin-11">{{ pageContent.title }}</h1>
    <div class="components__container">
      <div class="components__container_split-page">
        <div class="content-page content-page_first">
          <div class="content-page_section">
            <p
              class="my-5 content-page_section_content"
              v-html="pageContent.description.replace(/\n/g, '<br>')"
            ></p>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.planing.title }}
            </h3>
            <ul class="content-page_section_content">
              <li
                v-for="(item, index) in pageContent.planing.items"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.establish.title }}
            </h3>
            <ul class="content-page_section_content">
              <li
                v-for="(item, index) in pageContent.establish.items"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ pageContent.delivery.title }}
            </h3>
            <ul class="content-page_section_content">
              <li
                v-for="(item, index) in pageContent.delivery.items"
                :key="index"
              >
                {{ item }}
              </li>
            </ul>
          </div>
          <div class="content-page_section">
            <!-- <h3 class="content-page_section_header">
              {{ pageContent.operating.title }}
            </h3> -->
            <!-- <ul class="content-page_section_content">
              <li
                v-for="(item, index) in pageContent.operating.items"
                :key="index"
              >
                {{ item }}
              </li>
            </ul> -->
          </div>
          <div class="content-page_section">
            <p
              class="my-5 content-page_section_content"
              v-html="pageContent.additionalText.replace(/\n/g, '<br>')"
            ></p>
          </div>
        </div>
        <!-- <div
          style="width: 400px; height: 700px; background: rgb(184, 227, 184)"
        ></div> -->
        <div class="content-page content-page_second">
          <v-card class="contant-page_contact-form">
            <v-card-title class="contant-page_contact-form_header">{{
              $t("ContactUs")
            }}</v-card-title>
            <ContactUsForm :shortForm="true" />
          </v-card>
          <ServicesList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageInfo from "../../../PageContent/OperationalConsulting.json";
import ContactUsForm from "@/components/ContactUsForm.vue";
import ServicesList from "@/components/ServicesList.vue";
import { useHead } from "@vueuse/head";

export default {
  name: "OperationalConsulting",
  setup() {
    useHead({
      title: "ייעוץ תפעולי - RVc רוני ולטמן יועץ תפעול ותחזוקה",
      meta: [
        {
          // Meta Description
          name: "description",
          content:
            "ניתוח הצרכים באתר, השוואה בין שיטות ניהול, תפעול ותחזוקה עם כוח אדם עצמאי, הכנת אומדנים לתפעול בשיטות שונות, הגדרת מחשוב מערכי הניהול, התפעול והתחזוקה, הגדרת היקף כוח האדם וקישוריו. בדיקת אפשרות והמלצות לפיצול אגפים בבתים משותפים ועדויות מומחה בבתי משפט, הגדרת חלוקת אחריות בין יזם / משכיר לבין דייר / משתמש.",
        },
        {
          name: "keywords",
          content:
            "ייעוץ תפעולי, ניתוח הצרכים באתר, השוואה בין שיטות ניהול, תפעול ותחזוקה, הכנת אומדנים לתפעול בשיטות שונות, הגדרת מחשוב מערכי הניהול, התפעול והתחזוקה, הגדרת היקף כוח האדם וקישוריו, רוני ולטמן, מהנדס מומחה, ייעוץ, ניהול, אחזקה, פיצול אגפים בבתים משותפים ",
        },
        {
          // Open Graph Title
          property: "og:title",
          content: "ייעוץ תפעולי - RVc רוני ולטמן יועץ תפעול ותחזוקה",
        },
        {
          // Open Graph Description
          property: "og:description",
          content:
            "ניתוח הצרכים באתר, השוואה בין שיטות ניהול, תפעול ותחזוקה עם כוח אדם עצמאי, הכנת אומדנים לתפעול בשיטות שונות, הגדרת מחשוב מערכי הניהול, התפעול והתחזוקה, הגדרת היקף כוח האדם וקישוריו. בדיקת אפשרות והמלצות לפיצול אגפים בבתים משותפים ועדויות מומחה בבתי משפט, הגדרת חלוקת אחריות בין יזם / משכיר לבין דייר / משתמש.",
        },
        {
          // Open Graph Type
          property: "og:type",
          content: "website",
        },
        {
          // Open Graph URL
          property: "og:url",
          content: "https://rvc.co.il/operationalConsulting",
        },
        {
          // Viewport for Responsive Design
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          // Instructions for Search Engine Bots
          name: "robots",
          content: "index, follow",
        },
      ],
    });
  },
  components: {
    ContactUsForm,
    ServicesList,
  },
  data() {
    return {
      // Your component's data goes here
    };
  },
  computed: {
    pageContent() {
      return pageInfo[this.$i18n.locale];
    },
  },
};
</script>

<style scoped></style>
