<template>
  <div>
    <div class="welcome_section">
      <video
        autoplay
        loop
        muted
        :src="require('@/assets/videos/ronis-site-into.mp4')"
        id="intoVidoe"
      ></video>
      <div class="welcon_grid">
        <div class="d-flex flex-column justify-center align-center pb-12">
          <h1 class="welcome-title">
            {{ $t("RVC.title") }}
          </h1>
          <h2 class="welcome-title_text">
            <span class="yellow-name">{{ $t("RVC.textStart") }}</span>
            {{ $t("RVC.textEnd") }}
          </h2>
          <h3 id="welcome-subtitle">
            {{ $t("Reliability") }} {{ $t("Professionalism") }}
            {{ $t("Experience") }} {{ $t("Innovation") }}
          </h3>
        </div>
      </div>
    </div>
    <div class="services__grid content-page_section">
      <div v-for="(service, index) in serviesData" :key="index">
        <DisplayCard
          :frontContent="service[this.$i18n.locale].Front"
          :backContent="service[this.$i18n.locale].Back"
        />
      </div>
    </div>
    <div class="components__container py-6" style="width: 65%">
      <!-- <div class="components__container_split-page">
        <div class="content-page content-page_first">
          <div class="content-page_section">
            <p
              class="content-page_section_content"
              v-html="$t('About.longText').replace(/\n/g, '<br>')"
            ></p>
          </div>
        </div>
        <div class="content-page content-page_second">
          <div
            style="width: 100%; height: 92.5%"
            class="content-page_section d-flex justify-center"
          >
            <img
              class="home_image"
              :src="
                require('@/assets/images/Projects/NationalLibraryJerusalem.jpg')
              "
              alt=""
            />
          </div>
        </div>
      </div> -->
      <p
        class="content-page_section_content"
        style="text-align: center"
        v-html="$t('About.longText').replace(/\n/g, '<br>')"
      ></p>
    </div>
  </div>
</template>

<script>
import DisplayCard from "@/components/DisplayCard.vue";
import services from "../../PageContent/ServiceCardJson.json";
import { useHead } from "@vueuse/head";

export default {
  components: {
    DisplayCard,
  },
  setup() {
    useHead({
      title: "עמוד בית - RVc רוני ולטמן יועץ תפעול ותחזוקה",
      meta: [
        {
          // Meta Description
          name: "description",
          content:
            "המשרד עוסק בייעוץ בכל התחומים הקשורים לפרוגרמה, תכנון מהיבט ניהול, תפעול, אחזקה, ניקיון, אשפה וגינון, סקרי מצב נכסים, פעולות שיקום מבנים ומערכות, ושירותים נוספים לתשתיות, מבנים ומערכות במוסדות וארגונים שונים. השירותים כוללים גם מחשוב מערכי הנתונים והכנת אומדנים לתקציבי תפעול ותחזוקה רב שנתיים, הכנת עדויות מומחה לבתי משפט בנושאי הפרדה בבתים משותפים וכו'.",
        },
        {
          name: "keywords",
          content:
            "רוני ולטמן, COR ,מהנדס, RVc, ייעוץ, תכנון, תפעול, אחזקה, ניקיון, אשפה, גינון, סקרי מצב נכסים, פעולות שיקום, מערכות, תשתיות, מבנים, מוסדות, ארגונים, נתונים, אומדנים, תקציבי תפעול, תחזוקה, עדויות מומחה, בתי משפט, ייעוץ תחזוקה, ייעוץ אחזקה, ייעוץ תפעולי, ייעוץ תכנון, ייעוץ ניהול, ייעוץ נכסים, ייעוץ ניקיון, ייעוץ אשפה, ייעוץ מערכות, ייעוץ תשתיות, ייעוץ מבנים, ניהול, אחזקה, תחזוקה, נקיון, אפשה, ייעוץ תקציבי ת",
        },
        {
          // Open Graph Title
          property: "og:title",
          content: "RVc - רוני ולטמן יועץ תפעול ותחזוקה",
        },
        {
          // Open Graph Description
          property: "og:description",
          content:
            "המשרד עוסק בייעוץ בכל התחומים הקשורים לפרוגרמה, תכנון מהיבט ניהול, תפעול, אחזקה, ניקיון, אשפה וגינון, סקרי מצב נכסים, פעולות שיקום מבנים ומערכות, ושירותים נוספים לתשתיות, מבנים ומערכות במוסדות וארגונים שונים. השירותים כוללים גם מחשוב מערכי הנתונים והכנת אומדנים לתקציבי תפעול ותחזוקה רב שנתיים, הכנת עדויות מומחה לבתי משפט בנושאי הפרדה בבתים משותפים וכו'.",
        },
        {
          // Open Graph Type
          property: "og:type",
          content: "website",
        },
        {
          // Open Graph URL
          property: "og:url",
          content: "https://rvc.co.il",
        },
        {
          // Viewport for Responsive Design
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        {
          // Instructions for Search Engine Bots
          name: "robots",
          content: "index, follow",
        },
      ],
    });
  },
  data() {
    return {
      serviesData: services,
      description: "",
      title: "",
    };
  },
  created() {
    this.description = this.$t("About.shortText");
    this.title = this.$t("NavBarHeader.AppName");
  },
};
</script>

<style>
.services__grid {
  gap: 1.5rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* height: 90rem; */
  margin-top: 6rem;
  margin-bottom: 6rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.display-card__back {
  background: rgba(255, 166, 0, 0.63);
}

.display-card__front {
  /* background: #53bbde90; */
  background: #62a4d6d7;
}

.display-card .v-icon {
  font-size: 8rem !important;
}
.display-card .v-icon img {
  width: 8rem;
  height: 8rem;
}

.home_image {
  width: 100%;
  height: 100%;
  max-width: 450px;
  max-height: 450px;
  object-fit: cover;
}

.welcome_section {
  height: 35rem;
}

#intoVidoe {
  position: absolute;
  object-fit: cover;
  height: 35rem;
  width: 100%;
  opacity: 0.9;
}

.welcon_grid {
  padding-top: 3rem;
  padding-bottom: 0rem;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  height: 100%;
}

.v-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome_section h2 {
  font-size: 4rem;
  font-weight: 700;
  color: #fff;
  text-shadow: 4px 3px 4px #000;
  z-index: 1;
}

.services__grid {
  margin-top: 2rem;
}

.welcome_section__part {
  width: 50%;
  height: 100%;
}
.welcome-title {
  font-family: "Cursiv" !important;
  /* color: #fff; */
  color: rgb(68, 114, 196);
  font-size: 12rem;
  font-weight: 650;
  letter-spacing: 4px;
  opacity: 0.95;
  text-shadow: 4px 3px 4px #000;
  z-index: 1;
}

.welcome-title_text {
  color: white;
  font-size: 3rem;
  font-weight: 650;
  /* letter-spacing: 3px; */
  text-shadow: 4px 3px 4px #000;
  z-index: 1;
  display: none;
}

.yellow-name {
  color: rgba(251, 255, 1, 0.937);
  font-size: 5rem;
}

#welcome-subtitle {
  letter-spacing: 2px;
  text-shadow: 4px 3px 4px #000;
  color: white;
  z-index: 1;
  word-spacing: 3rem;
  margin-top: 1rem;
  display: none;
}

@media (min-width: 650px) {
  #welcome-subtitle {
    word-spacing: 7.5rem;
    display: block;
  }
  .welcome-title_text {
    display: block;
  }
  .services__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .services__grid {
    grid-template-columns: repeat(3, 1fr);
    height: 55rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .welcome_section {
    height: 40rem;
  }

  #intoVidoe {
    height: 40rem;
  }
}
@media (min-width: 1100px) {
}
@media (min-width: 1300px) {
  .home_image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .welcome_section {
    height: 45rem;
  }

  #intoVidoe {
    height: 45rem;
  }
}

@media (min-width: 1500px) {
  .services__grid {
    grid-template-columns: repeat(6, 1fr);
    height: 39rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .welcome_section {
    height: 45rem;
  }

  #intoVidoe {
    height: 45rem;
  }
}
@media (min-width: 1600px) {
  .services__grid {
    grid-template-columns: repeat(6, 1fr);
    height: 35rem;
    margin-left: 6rem;
    margin-right: 6rem;
  }
}
</style>

<style scoped>
@media screen and (min-width: 2000px) {
  .components__container {
    /* width: 65%; */
    max-width: 1300px;
  }
}
</style>
