<template>
  <v-list>
    <h3 class="mb-5">{{ $t("Services") }}</h3>
    <v-list-item
      v-for="(service, index) in serviesData"
      :key="index"
      @click="navigateTo(service.route)"
    >
      <v-list-item-title class="list_item">{{
        service[this.$i18n.locale]
      }}</v-list-item-title>
      <v-divider></v-divider>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: "ServicesList",
  data() {
    return {
      serviesData: {
        OperationalDesign: {
          route: "/operationalDesign",
          he: "בינוי תומך תפעול",
          en: "Operational Design",
        },
        MaintenanceConsulting: {
          route: "/maintenanceConsulting",
          he: "ייעוץ אחזקה",
          en: "Maintenance Consulting",
        },
        OperationalConsulting: {
          route: "/operationalConsulting",
          he: "ייעוץ תפעולי",
          en: "Operational Consulting",
        },
        TransportationConsulting: {
          route: "/transportationConsulting",
          he: "ייעוץ לפרוייקטי תחבורה",
          en: "Transportation Consulting",
        },
        WasteConsulting: {
          route: "/WasteConsulting",
          he: "ידעוץ אשפה",
          en: "Waste Consulting",
        },
        SludgeFund: { route: "/sludgeFund", he: "קרן פחת", en: "Sludge Fund" },
      },
    };
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.list_item {
  margin-bottom: 1rem;
  font-size: 1.5rem;
  padding-top: 0.5rem;
}

.v-list {
  padding: 3rem;
  margin: 5rem auto;
  max-width: 600px;
}
</style>
