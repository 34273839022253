<template>
  <div>
    <h1 class="content-page_main_header margin-11">
      {{ $t("About.title") }}
    </h1>
    <div class="components__container">
      <div class="components__container_split-page">
        <div class="content-page content-page_first">
          <div class="content-page_section">
            <p
              class="content-page_section_content"
              v-html="$t('About.shortText').replace(/\n/g, '<br>')"
            ></p>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">{{ education.title }}</h3>
            <ol class="content-page_section_content">
              <li v-for="(item, index) in education.items" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">{{ experience.title }}</h3>
            <ol class="content-page_section_content">
              <li v-for="(item, index) in experience.items" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">
              {{ publicActivity.title }}
            </h3>
            <ol class="content-page_section_content">
              <li v-for="(item, index) in publicActivity.items" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
          <div class="content-page_section">
            <h3 class="content-page_section_header">{{ activities.title }}</h3>
            <ol class="content-page_section_content">
              <li v-for="(item, index) in activities.items" :key="index">
                {{ item }}
              </li>
            </ol>
          </div>
        </div>
        <div class="content-page content-page_second">
          <!-- <img
            class="split-page_image"
            :src="
              require('@/assets/images/Projects/NationalLibraryJerusalem.jpg')
            "
            alt="site owner image"
          /> -->
          <ServicesList />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProjectImgae from "@/components/ProjectImgae.vue";
import ImageCarousel from "@/components/ImageCarousel.vue";
import ServicesList from "@/components/ServicesList.vue";

export default {
  components: {
    ProjectImgae,
    ImageCarousel,
    ServicesList,
  },
  data() {
    return {};
  },
  computed: {
    pageText() {
      return this.$i18n.locale === "he"
        ? require("../../AboutJsons/he.json")
        : require("/AboutJsons/en.json");
    },
    education() {
      let file_path =
        this.$i18n.locale === "he"
          ? require("../../AboutJsons/he.json")
          : require("/AboutJsons/en.json");
      return file_path.education ? file_path.education : [];
    },
    experience() {
      let file_path =
        this.$i18n.locale === "he"
          ? require("../../AboutJsons/he.json")
          : require("/AboutJsons/en.json");
      return file_path.experience ? file_path.experience : [];
    },
    publicActivity() {
      let file_path =
        this.$i18n.locale === "he"
          ? require("../../AboutJsons/he.json")
          : require("/AboutJsons/en.json");
      return file_path.publicActivity ? file_path.publicActivity : [];
    },
    activities() {
      let file_path =
        this.$i18n.locale === "he"
          ? require("../../AboutJsons/he.json")
          : require("/AboutJsons/en.json");
      return file_path.activities ? file_path.activities : [];
    },
  },
};
</script>

<style>
.rtl {
  direction: rtl;
}
.split-page_image {
  width: 100%;
  max-height: 60rem;
  /* object-fit: cover; */
}
</style>
