<template>
  <v-app-bar class="navbar" height="auto">
    <div v-if="showBtns" class="d-flex">
      <v-btn
        text
        v-for="item in getHeader()"
        :key="item.text"
        @click="navigateTo(item.route)"
        :active="false"
        class="navbar__link"
        :class="{ 'navbar_link_font_size--en': $i18n.locale === 'en' }"
        >{{ getText(item.text) }}</v-btn
      >
    </div>
    <div v-else>
      <v-menu v-model="menu" aria-label="Navigation Menu">
        <template v-slot:activator="{ props }">
          <v-app-bar-nav-icon
            class="navbar__link"
            @click="toggleMenu"
            v-bind="props"
          />
        </template>

        <v-list
          :class="[
            {
              'text-right': $i18n.locale === 'he',
            },
            'list__background',
          ]"
        >
          <v-list-item v-for="item in items" :key="item.text">
            <v-list-item-title
              @click="navigate(item.route)"
              class="text-h3 navbar__link"
              >{{ getText(item.text) }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-spacer></v-spacer>
    <v-select
      :items="languages"
      item-text="text"
      item-value="value"
      v-model="$i18n.locale"
      hide-details="true"
      style="display: inline-flex"
      bg-color="transparent"
    >
      <template v-slot:selection="{ item }">
        <v-img
          :src="getFlag(item.raw.url)"
          min-width="30px"
          max-width="30px"
          alt="flag"
        />
        <span v-if="showBtns" style="color: white" class="mx-2">{{
          item.raw.text
        }}</span>
      </template>
      <template v-slot:item="{ item, props }">
        <v-list-item v-bind="props" class="select-items">
          <div class="select-items">
            <v-img
              :src="getFlag(item.raw.url)"
              min-width="50px"
              max-width="50px"
            />
            <span v-if="showBtns" class="mx-2">{{ item.raw.text }}</span>
          </div>
        </v-list-item>
      </template>
    </v-select>
    <div class="navbar__heading" @click="navigateTo('/')">
      <!-- {{ $t("NavBarHeader.AppName") }} -->
      {{ $t("RVC.title") }}
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      menu: false,
      selectMenu: false,
      screenwidth: window.innerWidth,
      showBtns: false,
      items: [
        { text: "NavBarHeader.Home", route: "/" },
        { text: "NavBarHeader.About", route: "/about" },
        { text: "NavBarHeader.Projects", route: "/projects" },
        { text: "NavBarHeader.Contact", route: "/contact" },
      ],
      langValue: "",
      languages: [
        {
          title: "",
          text: "English",
          value: "en",
          url: "assets/images/flags/usa.png",
        },
        {
          title: "",
          text: "עברית",
          value: "he",
          url: "assets/images/flags/israel.jpg",
        },
      ],
    };
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
      window.scrollTo(0, 0);
    },
    getHeader() {
      return this.items;
    },
    getLanguage() {
      return this.$i18n.locale;
    },
    getText(name) {
      return this.$t(name);
    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    navigate(route) {
      this.$router.push(route);
      this.toggleMenu();
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
      this.isScreenBiggerThan1250();
    },
    isScreenBiggerThan1250() {
      this.showBtns = this.screenWidth >= 1250;
    },
    changeLang() {
      if (this.$i18n.locale === "he") {
        localStorage.setItem("lang", "en");
        this.$i18n.locale = "en";
      } else {
        localStorage.setItem("lang", "he");
        this.$i18n.locale = "he";
      }
      // this.$i18n.locale = this.$i18n.locale === "he" ? "en" : "he";
    },
    getFlag(url) {
      return require("@/" + url + "");
    },
  },
  created() {
    this.updateScreenWidth();
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
};
</script>

<style scoped>
.navbar {
  /* background-color: rgb(155, 131, 131) !important; */
  background-color: black !important;
  display: flex;
  justify-content: space-between !important;
  flex-wrap: wrap;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem !important;
  align-items: center;
}
.text-right {
  text-align: right;
}
.navbar__link {
  color: #b2b0b9;
  font-size: 1.8rem;
  padding: 0.5rem 2rem;
  align-items: center;
  cursor: pointer;
}
.navbar__heading {
  font-family: "Cursiv" !important;
  color: #fff;
  font-size: 2.8rem;
  line-height: normal;
  padding-bottom: 0.2rem;
  transition: all 0.8s;
}
.navbar__heading:hover {
  color: #fff;
  cursor: pointer;
}
.navbar__link:hover {
  color: #fff;
  cursor: pointer;
}
.list__background {
  margin-top: 0.4rem;
  background-color: #343131f0 !important;
}
img {
  width: 2.7rem;
  height: 1.8rem;
  margin-left: 1rem;
  cursor: pointer;
  font-size: medium;
}
.select-items {
  display: flex;
  align-items: center;
}
.v-input {
  flex: none;
}

.v-select :deep(.v-field__append-inner > .v-icon) {
  color: white;
  opacity: 0.85;
  margin: 0;
}
.v-select :deep(.v-field__input) {
  padding: 0rem;
}
@media screen and (min-width: 768px) {
  .navbar__heading {
    font-size: 3.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media screen and (min-width: 1250px) {
  .navbar__heading {
    font-size: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .headers-container {
    display: block;
  }
  .menu-container {
    display: none;
  }
  .navbar_link_font_size--en {
    font-size: 1.4rem;
  }
  .navbar_heading_font_size--en {
    font-size: 2.7rem;
  }
}
@media screen and (min-width: 1450px) {
  .navbar_link_font_size--en {
    font-size: 1.9rem;
  }
}
</style>
